<template>
    <div ref="gellary" v-if="Array.isArray(images)">
        <img class="img-thumbnail preview" v-for="(photo, indexPhoto) in images" v-bind:key="indexPhoto"
            :src="changePhotoPath(photo)" alt="" srcset="">
    </div>
    <div ref="gellary" v-if="!Array.isArray(images)">
        <!-- {{ images }} -->
        <img class="img-thumbnail preview" 
            :src="changePhotoPath(images)" alt="" srcset="">
    </div>
</template>

<script>
import config from '@/config';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

export default {
    name: 'ImagePreviewer',
    props: {
        images: undefined
    },
    methods: {
        changePhotoPath: function (photo) {
            if (photo) {
              if (photo.file_type === "photo_url"){
                return photo.photo_path
              }
                return config['host_addres'] + photo.photo_path
            }
        }
    },
    computed: {
        gallery() {
            let options = {
                button: false,
                navbar: false,
                title: false,
                toolbar: false,
            }
            return new Viewer(this.$refs.gellary, options);
        }
    },
    mounted() {
        this.gallery
    }
}
</script>

<style scoped>
.preview {
    width: 46%;
    margin: 5px;
    cursor: pointer;
}
.atach .preview{
    width: 100%;
}
</style>