import Vuex from 'vuex';
import axios from 'axios';
import config from '@/config';
import Cookies from 'js-cookie';

export const store = new Vuex.Store({
  state: {
    users: null,
    curent_user: null,
    messages: null,
    messages_listener: (event, context) => {
      let data = JSON.parse(event.data);
      context.commit('SET_MESSAGES', data);
    },
    event_sourse: null,
    isAuthorized: false,
    manager: {}
  },
  getters: {
    USERS: state => {
      return state.users;
    },
    CURENT_USER: state => {
      return state.curent_user;
    },
    MESSAGES: state => {
      return state.messages;
    },
    ISAUTHORIZSED: state => {
      return state.isAuthorized
    },
    MANAGER: state => {
      return state.manager
    },
  },
  mutations: {
    SET_USERS: (state, payload) => {
      state.users = payload;
    },
    SET_CURENT_USER: (state, payload) => {
      state.curent_user = payload;
    },
    SET_MESSAGES: (state, payload) => {
      state.messages = payload;
    },
    SET_ISAUTHORIZED: (state, payload) => {
      state.isAuthorized = payload;
    },
    SET_MANAGER: (state, payload) => {
      state.manager = payload;
    },
  },
  actions: {
    GET_USERS: async (context) => {
      let token = Cookies.get('token')
      axios
        .get(config.host_addres + config.routes.getUsers, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        )
        .then(response => (context.commit('SET_USERS', response.data)))
        .catch((error) => {
          if (error.response.status == 401) {
            context.commit('SET_ISAUTHORIZED', false)
          }
        });
    },
    GET_USERS_STREAM: async (context) => {
      let es = new EventSource(config.host_addres + config.routes.getUsersStream, {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('token')
        }
      });

      es.addEventListener('message', event => {
        let data = JSON.parse(event.data);
        context.commit('SET_USERS', data);
      }, false);
    },
    GET_MESSAGES_STREAM: async (context, id) => {
      context.state.event_sourse = new EventSource(config['host_addres'] + '/api/stream/user/' + id + '/messages/');

      context.state.event_sourse.addEventListener('message', event => {
        context.state.messages_listener(event, context)

      }, false);
    },
    GET_VK_MESSAGES_STREAM: async (context, id) => {
      context.state.event_sourse = new EventSource(config['host_addres'] + '/api/stream/vk_user/' + id + '/messages/');

      context.state.event_sourse.addEventListener('message', event => {
        context.state.messages_listener(event, context)

      }, false);
    },
    UNSUBSCRIBE_MESSAGES: async (context) => {
      if (context.state.event_sourse) {
        context.state.event_sourse.close(); // закрываем соединение с сервером
        context.state.event_sourse = null; // сбрасываем переменную объекта
      }
    },
    GET_CURENT_MANAGER: async (context) => {
      axios
        .get(config.host_addres + config.routes.getCurManager, {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`
          }
        }
        )
        .then(response => (context.commit('SET_MANAGER', response.data)))
        .catch((error) => {
          if (error.response.status == 401) {
            context.commit('SET_ISAUTHORIZED', false)
          }
        });
    }
  },
});