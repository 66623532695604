<template>
  <div class="menu-buttons">
    <button v-if="isAuthorized_state" v-on:click="toDevMessages" class="exit-button btn btn-light">Сообщения разработчикам</button>
    <button v-if="isAuthorized_state" v-on:click="toComplaints" class="exit-button btn btn-light">Жалобы</button>
    <button v-if="curentManager.is_admin" v-on:click="toDashBoard" class="btn btn-light dashboard-button">Управление</button>
    <button v-if="isAuthorized_state" v-on:click="deauth" class="exit-button btn btn-light">Выход</button>
  </div>
  <div class="container">
    <div class="ks-page-content">
      <div class="ks-page-content-body">
        <div class="ks-messenger">
          <SideBar />
          <MessengerComponent v-if="curent_user" />
          <placeholder v-else />
          <MessengerInfo v-if="curent_user" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'
import MessengerComponent from './MessengerComponent.vue'
import MessengerInfo from './MessengerInfo.vue'
import placeholder from './placeholder.vue'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'


export default {
  name: 'MainWindow',
  components: {
    SideBar,
    MessengerComponent,
    MessengerInfo,
    placeholder,
  },
  data() {
    return {
      isAuthorized: false
    }
  },
  computed: {
    ...mapState({
      isAuthorized_state: state => state.isAuthorized,
      curentManager: state => state.manager,
      curent_user: state => state.curent_user
    })
  },
  watch: {
    isAuthorized_state(newValue) {
      this.$data.isAuthorized = newValue
      if (newValue) {
        this.$store.dispatch('GET_USERS');
      }
    },

  },
  methods: {
    deauth() {
      Cookies.remove('token')
      this.$store.commit('SET_ISAUTHORIZED', false)
      this.$router.push({ path: '/login' })
    },
    toDashBoard(){
      if (this.curentManager.is_admin) {
        this.$router.push({path:'/dashboard'})
      }
    },
    toComplaints(){
      this.$router.push({ path: '/complaints' })
    },
    toDevMessages(){
      this.$router.push({ path: '/devmessage' })
    }
  },
  mounted() {
    this.$store.dispatch('GET_USERS');

  }

}
</script>

<style scoped>
.menu-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.menu-buttons .btn {
  margin-left: 4px;
  margin-right: 4px;
}

.container {
  border: 1px solid #8080804d;
  border-radius: 7px;
  margin-top: 1%;
  padding: 0;
}

body {
  background: #eee;
  /* overflow: hidden; */
}

.ks-messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  height: 100%
}

@media screen and (max-width:560px) {
  .ks-messenger__ks-messages-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .ks-messenger__ks-messages-footer textarea {
    margin-bottom: 20px
  }
}
</style>