<template>
    <div class="navbar white ">
        <div class="navbar-item h5">
            {{  user ? user.username : vk_client.first_name +" "+ vk_client.last_name }}
        </div>
        <div class="nabigate-buttons">
            <div class="exit">
                <button v-on:click="closeMessages" class="exit-button btn btn-warning">Закрыть обращение</button>
            </div>
            <div class="exit">
                <button v-on:click="toMessages" class="exit-button btn btn-secondary">К сообщениям</button>
            </div>

        </div>
    </div>
    <div class="padding">
        <div class="tab-pane row show active" id="message">
            <div class="col-sm-8 col-lg-9">
                <div class="p-v-sm ">
                    <div class="streamline m-b">
                        <div class="sl-item">
                            <div class="sl-left">
                                <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="img-circle">
                            </div>
                            <div class="sl-content">
                                <div class="sl-date text-muted">{{ getFormatDate(dev_message.date) }}</div>
                                <div class="sl-author">
                                    <a>{{ user ? user.username : vk_client.first_name + " " + vk_client.last_name }}</a>
                                </div>
                                <div>
                                    <p v-html="formattedText">
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="sl-item" v-for="(answer, index) in dev_message.answers" :key="index">
                            <div class="sl-left">
                                <img src="https://png.pngtree.com/png-clipart/20190924/original/pngtree-user-vector-avatar-png-image_4830521.jpg"
                                    class="img-circle">
                            </div>
                            <div class="sl-content">
                                <div class="sl-date text-muted">{{ getFormatDate(answer.date_of_answer) }}</div>
                                <div class="sl-author">
                                    <a>{{ answer.manager_username }}</a>
                                </div>
                                <div>
                                    <p>
                                        {{ answer.answer_text }}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="sl-item">
                            <div class="sl-left">
                                <img src="https://png.pngtree.com/png-clipart/20190924/original/pngtree-user-vector-avatar-png-image_4830521.jpg"
                                    class="img-circle">
                            </div>
                            <div class="sl-content">
                                <div class="box in m-a-0" id="reply-2">
                                    <form>
                                        <textarea class="form-control no-border" rows="3" ref="textarea"
                                            placeholder="Напишите ответ...."></textarea>
                                    </form>
                                    <div class="box-footer clearfix">
                                        <button class="btn btn-info pull-right btn-sm"
                                            @click="sendAnswer">Отправить</button>
                                        <ul class="nav nav-pills nav-sm">
                                            <li class="nav-item"><a class="nav-link" href="#"><i
                                                        class="fa fa-camera text-muted"></i></a></li>
                                            <li class="nav-item"><a class="nav-link" href="#"><i
                                                        class="fa fa-video-camera text-muted"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-4 col-lg-3">
                <div class="row m-b">
                    <div class="col-xs-6">
                        <small class="text-muted">Имя пользователя</small>
                        <div class="_500">{{ user ? user.username : vk_client.first_name + " " + vk_client.last_name }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Ссылка на пользователя</small>
                        <div class="_500">
                            <a :href="user ? user.client_url : 'https://vk.com/id' + vk_client.id" target="_blank" class="__cf_email__">{{ user ? user.client_url : 'https://vk.com/id' + vk_client.id }}</a>
                        </div>

                    </div>
                </div>
                <div class="row m-b">
                    <div class="col-xs-6">
                        <small class="text-muted">Имя</small>
                        <div class="_500">{{ user ? user.name : vk_client.first_name + " " + vk_client.last_name }}</div>
                    </div>
                    <div v-if="user" class="col-xs-6">
                        <small class="text-muted">Город</small>
                        <div class="_500">{{ user ? user.city : null }}</div>
                    </div>
                    <div v-if="user" class="col-xs-6">
                        <small class="text-muted">Тип тату</small>
                        <div class="_500">{{ user ? user.tattoo_type : null }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Дата регистрации</small>
                        <div class="_500">{{ user ? getFormatDate(user.date_of_reg) : getFormatDate(vk_client.registration_time) }}</div>
                    </div>
                    <div v-if="user" class="col-xs-6">
                        <small class="text-muted">Модель</small>
                        <div class="_500">{{ user.is_model ? "Да" : "Нет" }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Мастер</small>
                        <div class="_500">{{ this.user.master_id ? "Да" : "Нет" }}</div>
                    </div>
                </div>

                <div v-if="user">
                    <small class="text-muted" type="button" data-bs-toggle="collapse" data-bs-target="#collapse"
                        aria-expanded="false">Стили <font-awesome-icon icon="caret-down" /></small>
                    <div class="collapse" id="collapse">
                        <ul>
                            <li v-for="(style, index2) in user.styles" :key="index2">{{ style.style_name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import jsCookie from 'js-cookie'
export default {
    name: 'AnswerToMessage',
    props: ['message_id'],
    data() {
        return {
            dev_message: {},
            user: {},
            vk_client: {},
        }
    },
    computed: {
        formattedText() {
            if (this.dev_message.text){
              return this.dev_message.text.replace(/\n/g, "<br>");
            }
            return ""
      }
    },
    created() {

        axios.get(config.host_addres + config.routes.getDevMessage + this.message_id, {
            headers: {
                Authorization: `Bearer ${jsCookie.get('token')}`,
            },
        })
            .then((response) => {
                this.$data.dev_message = response.data
                this.$data.user = response.data.user
                this.$data.vk_client = response.data.vk_client
            }
            )
            .catch(error => {
                console.log(error);
                if (error.response.status == 401) {
                    this.$store.commit('SET_ISAUTHORIZED', false)
                }
                alert(`Хммм, что то пошло не так\n ${error}`)
            })
    },
    methods: {
        getFormatDate: function (date) {
            date = new Date(date)

            return date.getDate() + "." + date.getMonth() + 1 + "."
                + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
        },
        sendAnswer: function () {
            let isMaster = this.user.master_id
            let data = {
                complaint_id: this.dev_message.id,
                answer_text: this.$refs.textarea.value,
                user_id: isMaster ? isMaster : this.user ? this.user.client_id : null,
                vk_client_id: this.vk_client ? this.vk_client.id : null,
            }
            axios.post(config.host_addres + config.routes.postDevAnswer, data, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`,
                },
            })
                .then((response) => {
                    this.$data.dev_message = response.data
                    this.$refs.textarea.value = ""
                }
                )
        },
        toMessages: function () {
            this.$router.push({ path: '/devmessage' })
        },
        closeMessages: function () {
            axios.delete(config.host_addres + config.routes.deletDevMessage, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`
                },
                data: { "client_id": this.message_id, "vk_client_id": null }
            },

            )
                .then((response) => {
                    if (response) {
                        this.$router.push({ path: '/devmessage' })
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                    }
                    alert(`Хммм, что то пошло не так\n ${error}`)
                })

    }
}
}
</script>



<style scoped>
.images-container {
    width: 40%;
}

.sl-content p:last-child {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.form-control {
    font-size: 1rem;
    font-weight: normal;
    line-height: normal;
}

.tab-pane {
    display: none;
}

.show {
    display: flex !important;
}

.exit {
    margin-left: 10px;
    margin-right: 10px;
}

.nabigate-buttons {
    display: flex;
}

.nav-item .active {
    background-color: #bae0fc96 !important;
}</style>