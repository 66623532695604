<template>
    <div class="text-center">
        <main class="form-signin">
            <form @submit="logIn">

                <h1 class="h3 mb-3 fw-normal">Вход</h1>

                <div class="form-floating">
                    <input type="username" v-model="creds.username" class="form-control" id="floatingInput"
                        placeholder="username">
                    <label for="floatingInput">Имя пользователя</label>
                </div>
                <div class="form-floating">
                    <input type="password" v-model="creds.password" class="form-control" id="floatingPassword"
                        placeholder="Password">
                    <label for="floatingPassword">Пароль</label>
                </div>


                <button class="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
            </form>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import Cookies from 'js-cookie'
export default {
    name: 'LoginWindow',
    data() {
        return {
            creds: {
                username: "",
                password: ""
            }
        }
    },
    methods: {
        logIn: function (e) {
            e.preventDefault()
            let formData = new FormData();
            formData.append('username', this.creds.username)
            formData.append('password', this.creds.password)

            axios.post(config.host_addres + config.routes.getToken, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    let token = response.data.access_token
                    // Установка куки
                    Cookies.set('token', token, { expires: 1 });
                    this.$store.commit('SET_ISAUTHORIZED', true)
                    this.$store.commit('SET_MANAGER', response.data.curent_manager)

                    this.$router.push({ path: '/' })
                })
                .catch(error => {
                    console.log(error)
                    let inputs = document.querySelectorAll('input')
                    inputs.forEach(el => {
                        el.classList.add('is-invalid')
                    });
                });
        }
    },
    mounted() {
        if (!this.$store.getters.ISAUTHORIZED) {
            axios.get(config.host_addres + config.routes.validateToken, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            })
                .then((response) => { 
                    this.$store.commit('SET_ISAUTHORIZED', true)
                    this.$store.commit('SET_MANAGER', response.data)
                 })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                        this.$store.commit('SET_MANAGER', {})
                    }
                })
        }


    }
}

</script>

<style scoped>
html,
body {
    height: 100%;
}

body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    justify-content: center;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}
</style>
