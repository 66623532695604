<template>
    <div class="navbar white ">
        <div class="navbar-item h5">
            {{ client.name }}
        </div>
        <div class="nabigate-buttons">
            <div class="exit">
                <button v-on:click="closeComplaint" class="exit-button btn btn-warning">Закрыть обращение</button>
            </div>
            <div class="exit">
                <button v-on:click="toComplaints" class="exit-button btn btn-secondary">К жалобам</button>
            </div>

        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#message" type="button"
                role="tab" aria-controls="home" aria-selected="true">Сообщение</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                role="tab" aria-controls="profile" aria-selected="false">Профиль мастера</button>
        </li>
    </ul>
    <div class="padding">
        <div class="tab-pane row show active" id="message">
            <div class="col-sm-8 col-lg-9">
                <div class="p-v-sm ">
                    <div class="streamline m-b">
                        <div class="sl-item">
                            <div class="sl-left">
                                <img src="https://bootdey.com/img/Content/avatar/avatar1.png" class="img-circle">
                            </div>
                            <div class="sl-content">
                                <div class="sl-date text-muted">{{ getFormatDate(complaint.complaint_date) }}</div>
                                <div class="sl-author">
                                    <a>{{ client.username }}</a>
                                </div>
                                <div>
                                    <p>
                                        {{ complaint.complaint_text }}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="sl-item" v-for="(answer, index) in complaint.answers" :key="index">
                            <div class="sl-left">
                                <img src="https://png.pngtree.com/png-clipart/20190924/original/pngtree-user-vector-avatar-png-image_4830521.jpg"
                                    class="img-circle">
                            </div>
                            <div class="sl-content">
                                <div class="sl-date text-muted">{{ getFormatDate(answer.date_of_answer) }}</div>
                                <div class="sl-author">
                                    <a>{{ answer.manager_username }}</a>
                                </div>
                                <div>
                                    <p>
                                        {{ answer.answer_text }}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="sl-item">
                            <div class="sl-left">
                                <img src="https://png.pngtree.com/png-clipart/20190924/original/pngtree-user-vector-avatar-png-image_4830521.jpg"
                                    class="img-circle">
                            </div>
                            <div class="sl-content">
                                <div class="box in m-a-0" id="reply-2">
                                    <form>
                                        <textarea class="form-control no-border" rows="3" ref="textarea"
                                            placeholder="Напишите ответ...."></textarea>
                                    </form>
                                    <div class="box-footer clearfix">
                                        <button class="btn btn-info pull-right btn-sm"
                                            @click="sendAnswer">Отправить</button>
                                        <ul class="nav nav-pills nav-sm">
                                            <li class="nav-item"><a class="nav-link" href="#"><i
                                                        class="fa fa-camera text-muted"></i></a></li>
                                            <li class="nav-item"><a class="nav-link" href="#"><i
                                                        class="fa fa-video-camera text-muted"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-4 col-lg-3">
                <div class="row m-b">
                    <div class="col-xs-6">
                        <small class="text-muted">Имя пользователя</small>
                        <div class="_500">{{ client.username }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Ссылка на пользователя</small>
                        <div class="_500">
                            <a :href="client.client_url" target="_blank" class="__cf_email__">{{ client.client_url }}</a>
                        </div>

                    </div>
                </div>
                <div class="row m-b">
                    <div class="col-xs-6">
                        <small class="text-muted">Имя</small>
                        <div class="_500">{{ client.name }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Город</small>
                        <div class="_500">{{ client.city }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Тип тату</small>
                        <div class="_500">{{ client.tattoo_type }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Дата регистрации</small>
                        <div class="_500">{{ getFormatDate(client.date_of_reg) }}</div>
                    </div>
                    <div class="col-xs-6">
                        <small class="text-muted">Модель</small>
                        <div class="_500">{{ client.is_model ? "Да" : "Нет" }}</div>
                    </div>
                </div>

                <div>
                    <small class="text-muted" type="button" data-bs-toggle="collapse" data-bs-target="#collapse"
                        aria-expanded="false">Стили <font-awesome-icon icon="caret-down" /></small>
                    <div class="collapse" id="collapse">
                        <ul>
                            <li v-for="(style, index2) in client.styles" :key="index2">{{ style.style_name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane flex-wrap" id="profile" role="tabpanel">
            <div class="row m-b col-md-2">
                <div class="col-xs-6">
                    <small class="text-muted">Имя</small>
                    <div class="_500">{{ master.name }}</div>
                </div>
                <div class="col-xs-6">
                    <small class="text-muted">Номер</small>
                    <div class="_500">{{ master.phone_number }}</div>
                </div>
            </div>
            <div class="row m-b col-md-2">
                <div class="col-xs-6">
                    <small class="text-muted">Имя пользователя</small>
                    <div class="_500">{{ master.username }}</div>
                </div>
                <div class="col-xs-6">
                    <small class="text-muted">ссылка на мастера</small>
                    <div>
                        <a :href="master.master_url" target="_blank" class="_500">{{ master.master_url }}</a>
                    </div>
                </div>
                <div class="col-xs-6">
                    <small class="text-muted">Город</small>
                    <div class="_500">{{ master.city }}</div>
                </div>
            </div>
            <div class="row col-md-8">
                <small class="text-muted">О себе</small>
                <div>{{ master.about_master }}</div>
            </div>
            <div class="col-md-12 images-container">
                <ImagePreviewer :images="master.photos" />
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import jsCookie from 'js-cookie'
import ImagePreviewer from '../ImagePreviewer.vue'
export default {
    name: 'AnswerComponent',
    components: {
        ImagePreviewer
    },
    props: ['complaint_id'],
    data() {
        return {
            complaint: {},
            client: {},
            master: {},
        }
    },
    created() {

        axios.get(config.host_addres + config.routes.getComplaint + this.complaint_id.complaint_id, {
            headers: {
                Authorization: `Bearer ${jsCookie.get('token')}`,
            },
        })
            .then((response) => {
                this.$data.complaint = response.data
                this.$data.client = response.data.client
                this.$data.master = response.data.master
            }
            )
            .catch(error => {
                console.log(error);
                if (error.response.status == 401) {
                    this.$store.commit('SET_ISAUTHORIZED', false)
                }
                alert(`Хммм, что то пошло не так\n ${error}`)
            })
    },
    methods: {
        getFormatDate: function (date) {
            date = new Date(date)

            return date.getDate() + "." + date.getMonth() + 1 + "."
                + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
        },
        sendAnswer: function () {
            let data = {
                complaint_id: this.complaint.complaint_id,
                answer_text: this.$refs.textarea.value,
                user_id: this.client.client_id
            }
            axios.post(config.host_addres + config.routes.postAnswer, data, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`,
                },
            })
                .then((response) => {
                    this.$refs.textarea.value = ""
                    this.$data.complaint = response.data
                }
                )
        },
        toComplaints: function () {
            this.$router.push({ path: '/complaints' })
        },
        closeComplaint: function () {
            axios.delete(config.host_addres + config.routes.deleteComplaint, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`
                },
                data: { "client_id": this.complaint_id.complaint_id }
            },

            )
                .then((response) => {
                    if (response) {
                        this.$router.push({ path: '/complaints' })
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                    }
                    alert(`Хммм, что то пошло не так\n ${error}`)
                })
        
    }
}
}
</script>



<style scoped>
.images-container {
    width: 40%;
}

.sl-content p:last-child {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.form-control {
    font-size: 1rem;
    font-weight: normal;
    line-height: normal;
}

.tab-pane {
    display: none;
}

.show {
    display: flex !important;
}

.exit {
    margin-left: 10px;
    margin-right: 10px;
}

.nabigate-buttons {
    display: flex;
}

.nav-item .active {
    background-color: #bae0fc96 !important;
}</style>