<template>
  <div class="ks-discussions">
    <!-- {{ users }} -->
    <!-- <div class="ks-search">
      <div class="input-icon icon-right icon icon-lg icon-color-primary">
        <input id="input-group-icon-text" type="text" class="form-control" placeholder="Search">
        <span class="icon-addon">
          <span class="la la-search"></span>
        </span>
      </div>
    </div> -->
    <div class="ks-body ks-scrollable jspScrollable" data-auto-height
      style="overflow-y: auto; padding: 0px; width: 339px; overflow-x: hidden;" tabindex="0">
      <div class="jspContainer" style="width: 339px; height: 550px;">
        <div class="jspPane">
          <ul class="ks-items" v-for="user in users" v-bind:key="user.client_id">
            <li class="ks-item"
            :class="{ 'unreaded': !user.last_message.readed,
             'ks-active' : curent_user ? user.client_id == curent_user.client_id : false}">
              <a v-on:click="selectUser(user)">
                <span class="ks-avatar">
                  <img src="https://bootdey.com/img/Content/avatar/avatar1.png" width="36" height="36">
                </span>
                <div class="ks-body">
                  <div v-if="!user.username" class="ks-name">
                    {{ user.name }}
                    <span class="ks-datetime">{{ getFormatDate(user.last_message.complaint_date) }}</span>
                  </div>
                  <div class="ks-name">
                    {{ user.username }}
                    <span class="ks-datetime">{{ getFormatDate(user.last_message.complaint_date) }}</span>
                  </div>
                  <div class="ks-message" v-if="user.last_message.text">{{ user.last_message.text }}</div>
                  <div class="ks-message" v-if="!user.last_message.text"><i>Вложение</i></div>
                </div>
              </a>
            </li>
            <!-- <li class="ks-item ks-active">
              <a href="#">
                <span class="ks-group-amount">3</span>
                <div class="ks-body">
                  <div class="ks-name">
                    Group Chat
                    <span class="ks-datetime">just now</span>
                  </div>
                  <div class="ks-message">
                    <img src="https://bootdey.com/img/Content/avatar/avatar1.png" width="18" height="18"
                      class="rounded-circle"> The weird
                    future of movie theater food
                  </div>
                </div>
              </a>
            </li> -->
            <!-- <li class="ks-item ks-unread">
              <a href="#">
                <span class="ks-group-amount">5</span>
                <div class="ks-body">
                  <div class="ks-name">
                    Eric George
                    <span class="ks-datetime">just now</span>
                  </div>
                  <div class="ks-message">
                    <img src="https://bootdey.com/img/Content/avatar/avatar2.png" width="18" height="18"
                      class="rounded-circle"> Why didn't he
                    come and talk to me...
                  </div>
                </div>
              </a>
            </li> -->


          </ul>
        </div>
        <div class="jspVerticalBar">
          <div class="jspCap jspCapTop"></div>
          <div class="jspCap jspCapBottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config';
import Cookies from 'js-cookie';
import {mapState} from 'vuex'
export default {
  name: 'SideBar',
  computed: {
    users() {
      return this.$store.getters.USERS
    },
    ...mapState({
            curent_user: state => state.curent_user,
        })
  },
  methods: {
    getFormatDate: function (date) {
      date = new Date(date)
      let now = new Date();
      if (date.getDay() == now.getDay()) {
        return date.getHours() + ":" + date.getMinutes()
      }
      return date.getDate() + "." + date.getMonth() + 1 + "." + date.getFullYear()
    },
    selectUser(user) {
      let data = {
        "client_id": user.client_id || null,
        "vk_client_id": user.vk_client_id || null,
      }
      axios.post(config.host_addres + config.routes.checkAsRead, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`
        }
      }
      )
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('SET_ISAUTHORIZED', false)
          }
        })
      this.$store.commit("SET_CURENT_USER", user);
    }

  },
  mounted() {
    this.$store.dispatch('GET_USERS_STREAM');
  }
}
</script>
<style scoped>
.unreaded {
  background-color: #5ed7ff24;
}

.ks-messenger .ks-discussions {
  background: #fff;
  width: 340px;
  border-right: 1px solid #dee0e1
}

.ks-messenger .ks-discussions>.ks-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #dee0e1
}

.ks-messenger .ks-discussions>.ks-search>.input-icon {
  width: 100%
}

.ks-messenger .ks-discussions>.ks-search .form-control {
  border: none;
  padding: 28px 20px
}

.ks-messenger .ks-discussions>.ks-search .icon-addon {
  color: rgba(58, 82, 155, .6)
}

.ks-messenger .ks-discussions>.ks-body .ks-items {
  list-style: none;
  padding: 0;
  margin: 0
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item {
  border-bottom: 1px solid #dee0e1
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  padding: 15px 20px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-group-amount {
  position: relative;
  top: 3px;
  margin-right: 12px;
  width: 36px;
  height: 36px;
  background-color: rgba(57, 81, 155, .1);
  text-align: center;
  line-height: 36px;
  -webkit-border-radius: 50%;
  border-radius: 50%
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-group-amount>.ks-badge {
  position: absolute;
  bottom: -1px;
  right: -3px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-avatar {
  position: relative;
  top: 3px;
  margin-right: 12px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-avatar>img {
  width: 36px;
  height: 36px;
  -webkit-border-radius: 50%;
  border-radius: 50%
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-avatar>.ks-badge {
  position: absolute;
  bottom: -3px;
  right: -3px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-message {
  font-size: 12px;
  color: #858585;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-message>img {
  position: relative;
  top: -2px;
  width: 18px;
  height: 18px;
  margin-right: 5px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  margin-bottom: 4px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item>a>.ks-body>.ks-name>.ks-datetime {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  color: #858585;
  position: relative;
  top: 3px
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-active {
  background: #ebeef5;
  color: #333;
  position: relative
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-active::before {
  content: '';
  width: 4px;
  height: 100%;
  background: #d7dceb;
  display: block;
  position: absolute;
  top: 0;
  left: 0
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-active>a>.ks-group-amount {
  background-color: rgba(57, 81, 155, .1)
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-unread {
  background: rgba(247, 202, 24, .1)
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-unread>a>.ks-body>.ks-message {
  color: #333
}

.ks-messenger .ks-discussions>.ks-body .ks-items>.ks-item.ks-unread>a>.ks-group-amount {
  background-color: rgba(222, 187, 12, .2)
}

.jspPane {
  padding: 0px;
  top: 0px;
  width: 100%;
}

.jspPane .ks-item a {
  text-decoration: none;
  cursor: pointer;
}

.jspPane .ks-item:hover {
  background-color: #8d8d8d2e;
}

@media screen and (max-width:800px) {
  .ks-messenger .ks-discussions {
    width: 100%
  }
}

#input-group-icon-text {
  height: 10px;
}</style>
