<template>
    <div class="ks-messages ks-messenger__messages">
        <div class="ks-body">
           <span>Выберите. кому вы хотите написать</span>
        </div>
        

    </div>
</template>

<script>


export default {
    name: 'MessengerComponent',
    data() {
        return {
            user: null
        }
    },

    mounted() {
        this.user = this.$store.getters.USERS
    }

}
</script>

<style scoped>
.ks-messages .jspScrollable {
    height: 480px;
    overflow: scroll;
    padding: 0px;
    width: 701px;
    overflow-x: clip;
}

.ks-messenger .ks-messages,
.ks-messenger__messages {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background: #fff
}

.ks-body{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ks-body span{
    background-color: #0088cc3b;
    padding: 5px;
    border-radius: 26px;

}
</style>