import MainWindow from '@/components/MainWindow'
import LoginWindow from '@/components/LoginWindow'
import ManagerDashboard from '@/components/adminDashboard/ManagerDashboard'
import CreateManager from '@/components/adminDashboard/CreateManager'
import UpdateManager from '@/components/adminDashboard/UpdateManager'
import ComplaintsPage from '@/components/contact_to_admin/ComplaintsPage'
import ViewerComponent from '@/components/contact_to_admin/ViewerComponent'
import AnswerComponent from '@/components/contact_to_admin/AnswerComponent'
import DevMessages from '@/components/messagesForDev/DevMessages'
import AnswerToMessage from '@/components/messagesForDev/AnswerToMessage'
import { createWebHashHistory, createRouter } from 'vue-router'
// import { store } from '@/store/index'
import axios from 'axios'
import Cookies from 'js-cookie'
import config from '@/config'

const routes = [
  { path: '/', component: MainWindow },
  { path: '/login', component: LoginWindow },
  { path: '/dashboard', component: ManagerDashboard },
  { path: '/create', component: CreateManager },
  {
    path: '/update/:id',
    component: UpdateManager,
    name: 'update-manager',
    props: route => ({
      manager: {
        id: route.params.id,
      }
    })
  },
  {
    path: '/complaints', component: ViewerComponent,
    children: [
      { path: '', component: ComplaintsPage },
      {
        path: ':complaint_id',
        component: AnswerComponent,
        props: route => ({
          complaint_id: {
            complaint_id: route.params.complaint_id
          }
        })
      }
    ]
  },
  {
    path: '/devmessage',
    component: ViewerComponent,
    children: [
      { path: '', component: DevMessages },
      {
        path: ':message_id',
        component: AnswerToMessage,
        props: route => ({
          message_id: route.params.message_id
        })
      }
    ]
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = 0
  const response = await axios.get(config.host_addres + config.routes.validateToken, {
    headers: {
      'Authorization': 'Bearer ' + Cookies.get('token')
    }
  }).catch((er) => console.log(er));
  if (response) {
    isAuthenticated = response.data
  }
  if (to.path === '/login' && isAuthenticated) {
    // Если пользователь авторизован и пытается перейти на страницу входа, перенаправляем его на главную страницу
    next('/');
  }
  if (to.path !== '/login' && !isAuthenticated) {
    // Если пользователь не авторизован и он не на странице логина, перенаправляем его на страницу логина
    next('/login');
  } else {
    // Если пользователь авторизован или находится на странице логина, продолжаем навигацию
    next();
  }
});

export default router