export default {
    host_addres: process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : "",
    routes:{
        checkAsRead: "/api/checkasread",
        validateToken: "/api/validatetoken",
        getToken: "/api/token",
        sendMessage: "/api/sendmessage",
        getUsers: "/api/users/",
        getUsersStream: "/api/stream/users",
        getCurManager: "/api/getcurmanager",
        getManagersList: "/api/managers",
        deleteManager: "/api/manager",
        createManager: "/api/registration",
        getManager: "/api/manager/",
        updateManager: "/api/manager",
        fileDownload: (file_path) => + file_path,
        sendFile: "/api/sendfile",
        deleteChat: "/api/chat/",
        getComplaints: "/api/complaints",
        deleteComplaint: "/api/complaints/",
        getComplaint: "/api/complaint/",
        postAnswer: "/api/answer/",
        getDevMessages: "/api/devmessages",
        deletDevMessage: "/api/devmessage",
        getDevMessage: "/api/devmessage/",
        postDevAnswer: "/api/devanswer/"
    }
}