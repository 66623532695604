<template>
    
     
    <div class="padding">
        <div class="exit">
            <button v-on:click="toDashBoard" class="btn btn-secondary dashboard-button">Управление</button>
        </div>
        <div class="box">
            <div class="box-header b-b">
                <h3>Добавить менеджера</h3>
            </div>
            <div class="box-body">
                <form data-ui-jp="parsley" id="form" v-on:submit="createManager">
                    <div id="rootwizard" data-ui-jp="bootstrapWizard">

                        <div class="tab-content">
                            <div>
                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" v-model="managerData.username" class="form-control"
                                        placeholder="Введите имя пользователя" required>
                                </div>
                                <div class="form-group">
                                    <label>Пароль</label>
                                    <input type="password" v-model="managerData.password" class="form-control"
                                        placeholder="Введите пароль" required>
                                </div>
                                <div class="form-group">
                                    <label>Краткая информация</label>
                                    <input type="text" v-model="managerData.about" class="form-control"
                                        placeholder="Введите текст">
                                </div>
                                <div class="checkbox">
                                    <label class="ui-check parsley-success">
                                        <input type="checkbox" name="check" v-model="managerData.is_admin"
                                            class="has-value"><i></i>Дать
                                        права администратора
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="list-inline pager wizard">
                            <button type="submit" class="btn btn-primary">Сохранить</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>
<script>
import config from '@/config';
import axios from 'axios';
import jsCookie from 'js-cookie';

export default {
    name: 'CreateManager',
    props:{
      manager: {}  
    },
    data() {
        return {
            managerData: {
                username: "",
                password: "",
                is_admin: false,
                about: ""
            }
        }
    },
    methods: {
        createManager: function () {
            let data = {
                username: this.managerData.username,
                password: this.managerData.password,
                is_admin: this.managerData.is_admin,
                about: this.managerData.about
            }
            axios.post(config.host_addres + config.routes.createManager, data, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`,
                }
            }).then((response) => {
                if (response.data) {
                    this.managerData.username = ""
                    this.managerData.password = ""
                    this.managerData.is_admin = ""
                    this.managerData.about = ""
                }

            }
            ).catch(error => {
                if (error.response) {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                    }
                }

                alert(`Хммм, что то пошло не так\n ${error}`)
            })
        },
        toDashBoard() {
            this.$router.push({ path: '/dashboard' })
        }
    }
}
</script>

<style scoped>
.exit {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 15px; */
    margin-bottom: 15px;
}
.form-group {
    margin-top: 10px;
}
</style>