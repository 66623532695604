<template>
    <div class="padding">
        <div class="exit">
            <button v-on:click="toMainWindow" class="exit-button btn btn-secondary">На главную</button>
        </div>
        <div class="box">
            <div class="box-header">
                <div class="box-title">
                    <h2>Жалобы</h2> 
                </div>
            </div>
            <div class="table-responsive" id="datatable">
                <table data-ui-jp="dataTable" class="table table-striped b-t b-b">
                    <thead>
                        <tr>
                            <th style="width:15%">Имя пользователя</th>
                            <th style="width:70%">Текст жалобы</th>
                            <th style="width:5%">Дата</th>
                            <th style="width:10%">действия</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(complaint, index) in complaintsList" v-bind:key="index">
                            <td>{{ complaint.client.username }}</td>
                            <td>
                                {{ complaint.complaint_text }}
                            </td>
                            <td>{{ getFormatDate(complaint.complaint_date) }}</td>
                            <td class="buttons">
                                <div>
                                    <font-awesome-icon class="button edit" v-on:click="toAnswer(complaint.complaint_id)"
                                        icon="envelope" />
                                    <font-awesome-icon class="button ban" v-on:click="deleteComplaite(complaint.complaint_id)"
                                        icon="ban" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import config from '@/config'
import jsCookie from 'js-cookie'

export default {
    name: 'ComplaintsPage',
    methods: {
        toMainWindow: function () {
            this.$router.push({ path: '/' })
        },
        getFormatDate: function (date) {
            date = new Date(date)

            return date.getDate() + "." + date.getMonth() + 1 + "."
                + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
        },
        toAnswer: function (complaint_id) {
            this.$router.push({ path: '/complaints/' + complaint_id, params: { manager: 1324 } })
        },
        deleteComplaite: function (complaint_id) {
            axios.delete(config.host_addres + config.routes.deleteComplaint, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`
                },
                data: { "client_id": complaint_id }
            },

            )
                .then((response) => {
                    if (response) {
                        let indexOfManagerToRemove = this.$data.complaintsList.findIndex(complaint => complaint.complaint_id === complaint_id);
                        if (indexOfManagerToRemove !== -1) {
                            this.$data.complaintsList.splice(indexOfManagerToRemove, 1);
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                    }
                    alert(`Хммм, что то пошло не так\n ${error}`)
                })
        }
    },

    data() {
        return {
            complaintsList: []
        }
    },
    mounted() {
        axios.get(config.host_addres + config.routes.getComplaints, {
            headers: {
                Authorization: `Bearer ${jsCookie.get('token')}`
            }
        })
            .then((response) => {
                this.$data.complaintsList = response.data
            })
            .catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('SET_ISAUTHORIZED', false)
                }
                alert(`Хммм, что то пошло не так\n ${error}`)
            })
    }
}
</script>

<style scoped>
@import url('@/assets/css/animate.css/animate.min.css');
@import url('@/assets/css/glyphicons/glyphicons.css');
@import url('@/assets/css/material-design-icons/material-design-icons.css');
@import url('@/assets/css/ionicons/css/ionicons.min.css');
@import url('@/assets/css/simple-line-icons/css/simple-line-icons.css');
@import url('@/assets/css/styles/style.css');
@import url('@/assets/css/styles/app.css');

.exit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.box-header {
    height: 50px;
}

.box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.box-title .button {
    margin-right: 15px;
}

.button {
    font-size: 26px;
    cursor: pointer;
    transition: 0.5s;
    padding: 6px;
}

.button:hover {
    /* font-size: 32px; */
    background-color: white;

    color: black;
    border-radius: 14px;
}

.buttons {
    vertical-align: middle;
    text-align: center;
}

.buttons div {
    display: flex;
    justify-content: space-evenly;

}

.ban {
    color: red;
}

.edit {
    color: #2c7df482;
}

.create {
    color: rgba(1, 129, 1, 0.7);
}
</style>