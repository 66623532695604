<template>
    <div class="padding">
        <div class="exit">
            <button v-on:click="toMainWindow" class="exit-button btn btn-secondary">На главную</button>
        </div>
        <div class="box">
            <div class="box-header">
                <div class="box-title">
                    <h2>Менеджеры</h2>
                    <div>
                        <font-awesome-icon icon="plus" v-on:click="toCreate" class="button create" />
                    </div>
                </div>
            </div>
            <div class="table-responsive" id="datatable">
                <table data-ui-jp="dataTable" class="table table-striped b-t b-b">
                    <thead>
                        <tr>
                            <th style="width:5%">id</th>
                            <th style="width:25%">Имя пользователя</th>
                            <th style="width:30%">О менеджере</th>
                            <th style="width:30%">Администратор</th>
                            <th style="width:5%">действия</th>

                        </tr>
                    </thead>
                    <tbody v-for="(manager, index) in managers_list" v-bind:key="index">
                        <tr>
                            <td>{{ manager.id }}</td>
                            <td>{{ manager.username }}</td>
                            <td>{{ manager.about }}</td>
                            <td>{{ manager.is_admin ? "Да" : "Нет"}}</td>
                            <td class="buttons">
                                <div>
                                    <font-awesome-icon class="button edit" v-on:click="toEdit(manager.id)" icon="user-pen" />
                                    <font-awesome-icon class="button ban" v-on:click="deleteManager(manager.id)"
                                        icon="ban" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config';
import axios from 'axios';
import jsCookie from 'js-cookie';

export default {
    name: 'ManagerDashboard',
    data() {
        return {
            managers_list: []
        }
    },
    methods: {
        toMainWindow: function () {
            this.$router.push({ path: '/' })
        },
        deleteManager: function (manager_id) {
            axios.delete(config.host_addres + config.routes.deleteManager, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`
                },
                data: { "client_id": manager_id }
            },

            )
                .then((response) => {
                    if (response) {
                        let indexOfManagerToRemove =  this.$data.managers_list.findIndex(manager => manager.id === manager_id);
                        if (indexOfManagerToRemove !== -1) {
                            this.$data.managers_list.splice(indexOfManagerToRemove, 1);
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                    }
                    alert(`Хммм, что то пошло не так\n ${error}`)
                })
        },
        toCreate: function (){
            this.$router.push({path:'/create'})
        },
        toEdit: function (manager_id){
            this.$router.push({path: '/update/'+manager_id, params:{manager:1324}})
        }
    },
    mounted() {
        axios.get(config.host_addres + config.routes.getManagersList, {
            headers: {
                Authorization: `Bearer ${jsCookie.get('token')}`
            }
        })
            .then((response) => {
                this.$data.managers_list = response.data
            })
            .catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('SET_ISAUTHORIZED', false)
                }
                alert(`Хммм, что то пошло не так\n ${error}`)
            })
    }
}
</script>

<style scoped>
@import url('@/assets/css/animate.css/animate.min.css');
@import url('@/assets/css/glyphicons/glyphicons.css');
@import url('@/assets/css/material-design-icons/material-design-icons.css');
@import url('@/assets/css/ionicons/css/ionicons.min.css');
@import url('@/assets/css/simple-line-icons/css/simple-line-icons.css');
@import url('@/assets/css/styles/style.css');
@import url('@/assets/css/styles/app.css');

.exit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.box-header {
    height: 50px;
}

.box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.box-title .button {
    margin-right: 15px;
}

.button {
    font-size: 26px;
    cursor: pointer;
    transition: 0.5s;
    padding: 6px;
}

.button:hover {
    /* font-size: 32px; */
    background-color: white;

    color: black;
    border-radius: 14px;
}

.buttons {
    vertical-align: middle;
    text-align: center;
}

.buttons div {
    display: flex;
    justify-content: space-evenly;

}

.ban {
    color: red;
}

.edit {
    color: #2c7df482;
}

.create {
    color: rgba(1, 129, 1, 0.7);
}
</style>