<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import config from './config';
export default {
  name: 'App',
  components: {

  },
  created() {
    axios.get(config.host_addres + config.routes.validateToken, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`
      }
    })
      .then((response) =>{ 
        this.$store.commit('SET_ISAUTHORIZED', true)
        this.$store.commit('SET_MANAGER', response.data)
    })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('SET_ISAUTHORIZED', false)
          this.$store.commit('SET_MANAGER', {})
        }
      })
  }
}
</script>

<style scoped>


</style>