<template>
    <div class="atachments-container">
        <div v-for="(attachment, index) in attachments" v-bind:key="index" class="atach">
            <div class="file" v-if="attachment.file_type == 'document'" data-bs-toggle="tooltip" data-bs-placement="top"
                :title="attachment.file_name" v-on:click="downloadFile(attachment)">
                <font-awesome-icon icon="file-lines" />
            </div>
            <ImagePreviewer :images="attachment" v-if="attachment.file_type == 'photo' || attachment.file_type == 'photo_url'" />

        </div>
    </div>
</template>

<script>
import ImagePreviewer from './ImagePreviewer.vue'
import { Tooltip } from 'bootstrap';
import axios from 'axios';
import config from '@/config';
import jsCookie from 'js-cookie';

export default {
    name: 'AttachmentViewer',
    components: {
        ImagePreviewer
    },
    data() {
        return {
            images: Array
        }
    },
    props: {
        attachments: Array
    },
    methods: {
        downloadFile(file) {
            axios.get(config.host_addres + file.photo_path + "?file_name=" + file.file_name, {
                headers: {
                    Authorization: `Bearer ${jsCookie.get('token')}`,
                },
                responseType: 'blob'
            })
                .then((response) => {
                    const href = URL.createObjectURL(response.data);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', file.file_name); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
        }
    },
    mounted() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })

    }
}
</script>
<style scoped>
.atachments{
    margin-top: 7px;
}
.atach:has(.file) {
    width: auto;
}

.atach {
    display: flex;
    width: 50%;
    margin-left: 8px;
}

.atachments-container {
    display: flex;
}

.atach:first-child {
    margin-left: 0px;
}

.file {
    font-size: 3rem;
    padding: 0px 20px 0 20px;
    background-color: #cecece;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
}

.file:hover {
    color: white;
}
</style>