<template>
    <div class="ks-info ks-messenger__info">
        <div class="ks-header">
            Информация о пользователе
        </div>
        <div class="ks-body">
            <div class="ks-item ks-user">
                <span class="ks-avatar ks-online">
                    <img src="https://bootdey.com/img/Content/avatar/avatar1.png" width="36" height="36"
                        class="rounded-circle">
                </span>
                <span class="ks-name">
                    {{ curent_user.name }}
                </span>
            </div>
            <div class="ks-item">
                <div class="ks-name">Username</div>
                <div class="ks-text">
                    {{ curent_user.username }}
                </div>
            </div>
            <div class="ks-item">
                <div class="ks-name">Ссылка на профиль</div>
                <div class="ks-text">
                    <a :href="curent_user.client_url" target="_blank" class="__cf_email__">{{ curent_user.client_url }}</a>
                </div>
            </div>
            <div class="ks-item">
                <div class="ks-name">Город</div>
                <div class="ks-text">
                    {{ curent_user.city }}
                </div>
            </div>
            <div class="ks-item">
                <div class="ks-name">Цвет татуировки</div>
                <div class="ks-text">
                    {{ curent_user.tattoo_type }}
                </div>
            </div>
            <div class="ks-item">
                <div class="ks-name" type="button" data-bs-toggle="collapse" data-bs-target="#collapse"
                    aria-expanded="false">
                    Стили <font-awesome-icon icon="caret-down" />
                </div>
                <div class="ks-text collapse" id="collapse">
                    <ul>
                        <li v-for="(style, index) in curent_user.styles" :key="index">{{ style.style_name }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="ks-controll">
            <button type="button" @click="deleteChat" class="btn btn-secondary">Закрыть чат</button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import jsCookie from 'js-cookie';
import config from '@/config'

export default {
    name: 'MessengerInfo',
    computed: mapState({
        curent_user: state => state.curent_user,
        curent_userAlias: 'user'
    }),
    methods: {
        deleteChat: function () {
            if (confirm('Вы действительно хотите удалить этот чат?')) {
                let data ={
                    cond: this.curent_user.last_message.chat_closed
                } 
                let id = this.curent_user.client_id || this.curent_user.vk_client_id
                this.$store.commit("SET_CURENT_USER", null)
                axios.post(config.host_addres + config.routes.deleteChat + id, data, {
                    headers: {
                        Authorization: `Bearer ${jsCookie.get('token')}`
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('SET_ISAUTHORIZED', false)
                    }
                    alert(`Хммм, что то пошло не так\n ${error}`)
                })
            }
        }
    }
    // mounted(){
    //     collapseContainer = document.getElementById('collaps')
    //     collapse = new Collapse(collapseContainer)
    // }
}
</script>

<style scoped>
.ks-controll {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ks-controll button {
    margin-top: 13px;
    margin-bottom: 21px;
}

.ks-item li .ks-messenger .ks-info,
.ks-messenger__info {
    width: 240px;
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #dee0e1
}

.ks-messenger .ks-info>.ks-header,
.ks-messenger__info>.ks-header {
    border-bottom: 1px solid #dee0e1;
    line-height: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    padding: 20px
}

.ks-messenger .ks-info>.ks-body,
.ks-messenger__info>.ks-body {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: 475px;
}

.ks-messenger .ks-info>.ks-body>.ks-item+.ks-item,
.ks-messenger__info>.ks-body>.ks-item+.ks-item {
    margin-top: 10px
}

.ks-messenger .ks-info>.ks-body>.ks-item.ks-user,
.ks-messenger__info>.ks-body>.ks-item.ks-user {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex
}

.ks-messenger .ks-info>.ks-body>.ks-item.ks-user>.ks-avatar,
.ks-messenger__info>.ks-body>.ks-item.ks-user>.ks-avatar {
    margin-right: 12px
}

.ks-messenger .ks-info>.ks-body>.ks-item.ks-user>.ks-name,
.ks-messenger__info>.ks-body>.ks-item.ks-user>.ks-name {
    line-height: 31px;
    color: #333
}

.ks-messenger .ks-info>.ks-body>.ks-item>.ks-name,
.ks-messenger__info>.ks-body>.ks-item>.ks-name {
    color: #858585;
    margin-bottom: 3px
}

.ks-messenger .ks-info>.ks-body>.ks-list>.ks-header,
.ks-messenger__info>.ks-body>.ks-list>.ks-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #858585;
    margin-bottom: 15px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item,
.ks-messenger__info>.ks-body>.ks-list .ks-item {
    margin-bottom: 15px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item.ks-user,
.ks-messenger__info>.ks-body>.ks-list .ks-item.ks-user {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item.ks-user>.ks-avatar,
.ks-messenger__info>.ks-body>.ks-list .ks-item.ks-user>.ks-avatar {
    margin-right: 12px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item.ks-user>.ks-name,
.ks-messenger__info>.ks-body>.ks-list .ks-item.ks-user>.ks-name {
    line-height: 31px;
    color: #333
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item>.ks-owner,
.ks-messenger__info>.ks-body>.ks-list .ks-item>.ks-owner {
    position: relative;
    top: 1px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-name,
.ks-messenger__info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-name {
    display: block;
    line-height: 13px
}

.ks-messenger .ks-info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-label-sm,
.ks-messenger__info>.ks-body>.ks-list .ks-item>.ks-owner>.ks-label-sm {
    padding: 2px;
    font-size: 9px
}

.ks-messenger .ks-info>.ks-footer,
.ks-messenger__info>.ks-footer {
    padding: 20px;
    border-top: 1px solid #dee0e1
}

.ks-messenger .ks-info>.ks-footer>.ks-item+.ks-item,
.ks-messenger__info>.ks-footer>.ks-item+.ks-item {
    margin-top: 10px
}

.ks-messenger .ks-info>.ks-footer>.ks-item>.ks-name,
.ks-messenger__info>.ks-footer>.ks-item>.ks-name {
    color: #858585;
    margin-bottom: 3px
}

.ks-messenger .ks-info>.ks-footer>.btn-block,
.ks-messenger__info>.ks-footer>.btn-block {
    margin-top: 15px
}

.ks-search #input-group-icon-text {
    height: 10px;
}

@media screen and (max-width:1200px) {
    .btn.ks-messenger-info-block-toggle {
        position: static
    }

    .ks-messenger .ks-info,
    .ks-messenger__info {
        position: fixed;
        top: 120px;
        bottom: 0;
        right: -241px;
        z-index: 4;
        height: -webkit-calc(100% - 120px);
        height: calc(100% - 120px)
    }

    .ks-messenger .ks-info.ks-open,
    .ks-messenger__info.ks-open {
        right: 0;
        -webkit-transition: right .2s ease;
        transition: right .2s ease
    }
}
</style>